import { fetchApi } from '@/utils/apiCall'

export const getAuctionsByCity = async (reqHeaders, cityId, isSaudi) => {
  if (!isSaudi && !cityId) return null
  try {
    const { locale } = reqHeaders
    const reqBody = JSON.stringify(isSaudi ? {} : { cityId })
    const res = await fetch(`/api/auction/crud?apiUrl=auction-city-filter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        locale,
      },
      data: reqBody,
    })
    const data = res?.data?.data

    if (res?.status) {
      return data
    } else {
      throw res?.status
    }
  } catch (error) {
    return error?.response
  }
}

export const getClientSideCityAuctions = async (lang, cityId) => {
  let auctionResponseData = {}
  try {
    auctionResponseData = await fetchApi({
      lang,
      baseUrl: 'NEXT_PUBLIC_AUCTION_URL',
      subUrl: 'auction-city-filter',
      method: 'POST',
      reqBody: {
        cityId,
      },
    })
  } catch (error) {
    console.error('error', error)
    auctionResponseData = {}
  }
  return auctionResponseData?.data?.data
}

export const isAuctionLiveCount = async () => {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_AUCTION_URL}auction-count`)
    const response = await res.json()
    return [response?.data?.liveCount, response?.data?.upcomingCount]
  } catch (error) {
    return null
  }
}
